import { Link } from 'react-router-dom';
import VideoPlayer from "./VideoPlayer"

const Items = ({ background, type, img_title, title, subtitles, paragraphs, content, minia, button, link }) => {

    return (
        <div className={`${background} itemBlock itemBlock${type}`}>
            <div data-aos="fade-up">
                {content.slice((content.lastIndexOf('.') - 1 >>> 0) + 2) === 'mp4'
                    ? <video className="itemMedia" controls={true} poster={minia}><source src={content}/></video>
                    : <img className="itemMedia" src={content} alt="" />}
            </div>
            <div className={`itemText itemText${type}`} data-aos="fade-up">
                {img_title && <img src={img_title} alt="" />}
                <h1>{title}</h1>
                {paragraphs.map((paragraph, index) => (
                    <div>
                    {subtitles && subtitles[index] ? <h2>{subtitles[index]}</h2> : null}
                    <p key={index}>{paragraph}</p>
                    </div>
                ))}
            {link? <a href={link.src} target='_blank'> {link.text} </a>
            : null}
            </div>
        </div>
    );
};

export default Items;