import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import Slider from '../components/TestSlider';
import FirstSlide from "../components/FirstSlide";

import bird from '../assets/img/doodles/martin-pecheur.png';
import butterfly01 from '../assets/img/doodles/papillon_003.png';
import butterfly02 from '../assets/img/doodles/papillon_002.png';
import chardon01 from '../assets/img/doodles/chardon.png'
import chardon02 from '../assets/img/doodles/chardon_002.png'
import fleur01 from '../assets/img/doodles/fleurs_001.png'

import HeadBand from '../components/HeadBand';
import Item from '../components/Item';
import coquillage from '../assets/img/doodles/coquillages_002.png'
import image1 from '../assets/img/team2.jpg'
import image2 from '../assets/img/barthelemy.jpg'
import pdf from '../assets/docs/hebergements.pdf';
import marmotte from '../assets/img/doodles/marmotte.png'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { auto } from "@popperjs/core";

const Informations = () => {

    const i18n = useTranslation();

    const [currentSlide, setCurrentSlide] = useState(0); // Ajoutez un état pour suivre l'indice de la diapositive actuelle

    const handleSlideChange = (index) => {
        setCurrentSlide(index); // Mettez à jour l'indice de la diapositive actuelle
    }

    return (
        window.innerWidth > 950 ?
        <div className="itemBlock customBlock">
            <Container>
                <Row>
                    <Col>       
                         <h1> {i18n.t("Informations.Dormir.title")}</h1>
                    </Col>
                </Row>
                <Row style={{marginBottom:"10vh"}}>
                    <Col>       
                         <h3> {i18n.t("Informations.Dormir.paragraph_1")}</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <img src={marmotte}></img>
                    </Col>
                    <Col>
                        <h3 className="smallCaps">
                            Dormir au château
                        </h3>
                        <p>
                            {i18n.t("Informations.Dormir.paragraph_2")}
                        </p>
                        {
                            //<div style={{height:"2vh", width:"auto", marginBottom:"2vh", borderBottom:"solid black 2px" }}></div>}
                        }
                        <div style={{width:"100%",     textAlign: "center"}}>
                            <img src={coquillage} style={{height:"10vh", width:"auto"}}></img>
                        </div>

                        <h3 className="smallCaps">
                            Dormir dans les environs
                        </h3>
                        <p>
                            {i18n.t("Informations.Dormir.paragraph_3")}
                        </p>
                        <u><a style={{fontSize:"1.2em"}} href={pdf} target="_blank">Liste des hébergements à proximité</a></u>

                    </Col>
                </Row>
            </Container>
        </div>
        :
        <div>
            <Item
                background="bg3"
                type={window.innerWidth > 950 ? "2" : ""}
                title={i18n.t("Informations.Dormir.title")}
                subtitles={["", "Dormir au château", "Dormir dans les environs"]}
                paragraphs={[i18n.t("Informations.Dormir.paragraph_1"), i18n.t("Informations.Dormir.paragraph_2"), i18n.t("Informations.Dormir.paragraph_3")]}
                content={image2}
                button=""
                link={
                    {
                        text:"Liste des hébergements à proximité",
                        src:pdf,
                    }}
            />
        </div>
    );
};

export default Informations;