import { Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React, { useRef } from "react";

import mairie from '../assets/img/doodles/algues_001.png'
 


const HeadBand = ({ background, title, paragraphs, paragraphs2, btn, link }) => {

    const headBandRef = useRef(null);

    const handleClick = () => {
        if (headBandRef.current) {
            const headBandRef = headBandRef.current.clientHeight;
            window.scroll({
                top: headBandRef - window.innerHeight * 0.1,
                behavior: "smooth"
            });
        }
    }
    
    return (<div className={background}>
        <div className="headBandText" data-aos="fade">
            <h1>{title}</h1>
            {paragraphs &&
            (paragraphs.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            )))}
            {btn && (
                <Link to={link}>
                    <button data-aos="fade" data-aos-duration="2000">
                        {btn}
                    </button>
                </Link>
            )}
            {paragraphs2 &&
            (paragraphs2.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
            )))}
            <Container>
                <Row>
                    <Col>
                        <h2>La Mairie</h2>
                        <img className="headBandMedia" src={mairie} alt="" />
                        <p>28 juin 2024</p>
                        <div className="headBandBgScroll">
                        <svg width="50px" height="30px" onClick={handleClick}>
                            <path
                                stroke="#000000"
                                fill="none"
                                strokeWidth="2px"
                                d="M3.000,5.000 L15.000,18.000 L28.000,5.000"
                            />
                        </svg>
                        </div>

                    </Col>
                    <Col>
                        <h2>La Cérémonie</h2>
                        <img className="headBandMedia" src={mairie} alt="" />
                        <p>29 août 2024</p>
                        <div className="headBandBgScroll">
                        <svg width="50px" height="30px" onClick={handleClick}>
                            <path
                                stroke="#000000"
                                fill="none"
                                strokeWidth="2px"
                                d="M3.000,5.000 L15.000,18.000 L28.000,5.000"
                            />
                        </svg>
                        </div>
                    </Col>
                    <Col>
                        <h2>Le Brunch</h2>
                        <img className="headBandMedia" src={mairie} alt="" />
                        <p>30 août 2024</p>
                        <div className="headBandBgScroll">
                        <svg width="50px" height="30px" onClick={handleClick}>
                            <path
                                stroke="#000000"
                                fill="none"
                                strokeWidth="2px"
                                d="M3.000,5.000 L15.000,18.000 L28.000,5.000"
                            />
                        </svg>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </div>
);

};

export default HeadBand;