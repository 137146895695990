import React, { useState } from 'react'
import { NavLink } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import useToggle from "../hooks/useToggle"

import { FaCaretDown } from "react-icons/fa"

const Hamburger = () => {

    /* Language Selector */
    const { t, i18n } = useTranslation();
    const handleLanguageChange = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem("lng", lng);
    };

    const [languageClass, languageToggle] = useToggle("hamburgerLanguage fadeUp", "hamburgerLanguage fadeDown", 250);

    const renderLanguageOptions = () => (
        <div className={languageClass}>
            <p onClick={() => handleLanguageChange("fr")}>Français</p>
            <p onClick={() => handleLanguageChange("en")}>English</p>
        </div>
    );

    /* Menu Toggle */
    const [hamburger, setHamburger] = useState(false);
    const [menuClass, menuToggle] = useToggle("hamburgerMenu slideRight", "hamburgerMenu slideLeft", 250);
    const [overlayClass, overlayToggle] = useToggle("hamburgerOverlay fadeOut", "hamburgerOverlay fadeIn", 250);

    const toggle = () => {
        setHamburger(!hamburger);
        menuToggle();
        overlayToggle();
    }

    return (
        <div>
            <div className={overlayClass} onClick={toggle}></div>
            <div className="hamburgerBlock" onClick={toggle}>
                <div className={(hamburger ? "clicked hamburgerTop" : "hamburgerTop")}></div>
                <div className={(hamburger ? "disabled" : "hamburgerMid")}></div>
                <div className={(hamburger ? "clicked hamburgerBot" : "hamburgerBot")}></div>
            </div>
            <div className={menuClass}>
                <ul>
                    <li><NavLink to="/" className={({isActive}) => isActive ? "navActive" : ""} onClick={toggle}>{t("Home")}</NavLink></li>
                    <li><NavLink to="/informations" className={({isActive}) => isActive ? "navActive" : ""} onClick={toggle}>{t("Usage")}</NavLink></li>
                    <li><NavLink to="/cadeau" className={({isActive}) => isActive ? "navActive" : ""} onClick={toggle}>{t("Team")}</NavLink></li>
                    <li><NavLink to="/contact" className={({isActive}) => isActive ? "navActive" : ""} onClick={toggle}>{t("RSVP")}</NavLink></li>
                </ul>
            </div>
        </div>
    );
};

export default Hamburger;