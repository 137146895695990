import React, { useEffect } from 'react';
import {Routes, Route, useLocation } from "react-router-dom";
import "./i18next";
import AOS from 'aos';

import Navbar from './components/Navbar';
import Footer from './components/Footer';

import Home from "./pages/Home"
import Usage from "./pages/Usage"
import Team from "./pages/Team"
import Contact from "./pages/Contact"
import Legal from "./pages/Legal"
import Informations from 'pages/Informations';

function App() {

  const location = useLocation();

  useEffect(() => {
    window.scrollTo({top: 0});
    AOS.init({ duration: 1000 });
  });

  return (
    <>
      <Navbar />
        <Routes location={location} key={location.pathname}>
          <Route index element={<Home />} />
          <Route path="/informations" element={<Informations />} />
          <Route path="/cadeau" element={<Team />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      <Footer />
    </>
  );
};

export default App;
