import { useTranslation } from "react-i18next";
import React, { useRef } from "react";

import video from '../assets/vid/teaser.mp4'
import image from '../assets/img/logo.jpeg'

const Videobg = () => {

    const i18n = useTranslation();
    const videoBgContentRef = useRef(null);

    const handleClick = () => {
        if (videoBgContentRef.current) {
            const videoBgContentHeight = videoBgContentRef.current.clientHeight;
            window.scroll({
                top: videoBgContentHeight - window.innerHeight * 0.1,
                behavior: "smooth"
            });
        }
    }
   // <video autoPlay loop muted playsinline="" data-wf-ignore="true"><source src={video} data-wf-ignore="true" /></video>
//   <img src={image} alt="logo" /> <br /> <br />

    return (
        <div className="videoBgBlock">
            <div className="videoBgContent" ref={videoBgContentRef}>
                <div className="videoTextContent">
                    <h1>{i18n.t("Future is invented here")} </h1>
                    <h1>{i18n.t("mariage date")} </h1>
                    <h1>{i18n.t("mariage lieu")} </h1>
                </div>
            </div>
        </div>
    );
};

export default Videobg;
