import { useTranslation } from "react-i18next";
import HeadBand from '../components/HeadBand';
import Item from '../components/Item';

import image1 from '../assets/img/team2.jpg'
import image2 from '../assets/img/photo_cadeau.jpg'

const Career = () => {

    const i18n = useTranslation();

    return (
        <div>
            <Item
                background="bg3"
                type="2"
                title={i18n.t("Cadeau.Idees.title")}
                paragraphs={[i18n.t("Cadeau.Idees.paragraph_1")]}
                content={image2}
                button=""
            />
        </div>
    );
};

export default Career;

/* <Item
                background="bg3"
                type="2"
                title={i18n.t("Career page.Image 1.title")}
                paragraphs={[i18n.t("Career page.Image 1.paragraph"), i18n.t("Career page.Image 1.paragraph 2")]}
                content={image1}
                button=""
            /> */