import React, { useRef } from "react";
import baie from '../assets/img/doodles/baies_001.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import olivier from '../assets/img/doodles/olivier.png'
import corail from '../assets/img/doodles/corail.png'
import couple from '../assets/img/doodles/Jess_et_Samy.png'

const FirstSlide = (props) => {
    const { type, image, title, dates, lieux, handleSlideChange } = props;

    const videoBgContentRef = useRef(null);

    const handleClick = () => {
        if (videoBgContentRef.current) {
            const videoBgContentHeight = videoBgContentRef.current.clientHeight;
            window.scroll({
                top: 2*videoBgContentHeight - window.innerHeight * 0.1,
                behavior: "smooth"
            });
        }
    }

    const handleIndicatorClick = (index) => {
        handleSlideChange(index);
    };

    return (
        <div>
            <div className="sliderTitleBanderino"><img src={baie}></img> <h1 className="sliderBanderino" data-aos="fade">{title[0]}</h1></div>
                <Container className={"sliderBlock" + type} ref={videoBgContentRef}>


                    <Row>
                        <Col className="text-center">
                        <div className="firstSlideBackground">
                            <img className="firstSlideImg" alt="" src={olivier}/>
                        </div>
                            <h3>28 juin 2024</h3>
                            <div style={{borderLeft:"solid 2px #292C9E", width: "3px",  height:"5vh", margin:"auto"}}></div>
                        </Col>
                        <Col className="text-center">
                            <h2 style={window.innerWidth>950 ? {marginTop:"20vh"} : {marginTop:"10vh"}}>La Cérémonie</h2>
                        </Col>
                        <Col className="text-center">
                        <div className="firstSlideBackground">
                            <img className="firstSlideImg" alt="" src={corail}/>
                        </div>
                            <h3>30 août 2024</h3>
                            <div style={{borderLeft:"solid 2px #292C9E", width: "3px",  height:"5vh", margin:"auto"}}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                        <div style={{ borderBottom:"solid 5px #292C9E", width: "100%", zIndex:"10", height:"5px"}}>
                        </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <h2 style={{marginTop:"5vh"}}>La Mairie</h2>

                        </Col>
                        <Col className="text-center">
                            <div style={{borderLeft:"solid 2px #292C9E", width: "3px",  height:"5vh", margin:"auto"}}></div>
                            <h3>29 août 2024</h3>
                            <div className="firstSlideBackground">
                                <img className="firstSlideImg" alt="" src={couple}>
                                </img>
                            </div>
                        </Col>
                        <Col className="text-center">
                            <h2 style={{marginTop:"5vh"}}>Le Brunch</h2>
                        </Col>
                    </Row>
                </Container>
        </div>
    );
};

export default FirstSlide;
