/*import { useTranslation } from "react-i18next";

const Contact = () => {

    const i18n = useTranslation();

    return (
        <div className="contactBlock">
            <form>
                <h1>{i18n.t("Contact us")}</h1>
                <div>
                    <input
                        className="contactContent"
                        id="name"
                        name="name"
                        placeholder={i18n.t("Contact.name")}
                        type="text"
                        autoComplete="true"
                        required
                    />
                </div>
                <div>
                    <input
                        className="contactContent"
                        id="email"
                        name="email"
                        placeholder={i18n.t("Contact.e-mail")}
                        type="email"
                        autoComplete="true"
                        required
                    />
                </div>
                <div>
                    <textarea
                        className="contactContent"
                        id="message"
                        name="message"
                        placeholder={i18n.t("Contact.message")}
                        rows="5"
                        required
                    ></textarea>
                </div>
                <button type="submit">
                    {i18n.t("Contact.send")}
                </button>
            </form>
        </div>
    );
};

export default Contact;*/

import { useForm, ValidationError } from '@formspree/react';
import { useTranslation } from "react-i18next";

const Contact = () => {

    const i18n = useTranslation();
    console.log(window.innerWidth);
    return (
        <div style={{display:"flex", justifyContent:"center"}}>
            <iframe style={{marginTop:"10vh"}} src="https://docs.google.com/forms/d/e/1FAIpQLSddoXBKh7IsTCvTG6SzP38rKPYI9NgRP43Zt4GkVSS1PdXhBg/viewform?embedded=true"
             width= {640} height="2400" frameborder="0" marginheight="0" marginwidth="0">Chargement…</iframe>
        </div>
    );
}

export default Contact;