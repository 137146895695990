import { useTranslation } from "react-i18next";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slides from "./TestSlide";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import mairie from '../assets/img/mairie_16e.jpeg';
import ceremonie from '../assets/img/barthelemy.jpg';
import brunch from '../assets/img/brunch.jpg';
import olivier from '../assets/img/doodles/olivier.png'
import corail from '../assets/img/doodles/corail.png'
import figue from '../assets/img/doodles/figues_001.png'
import couple from '../assets/img/doodles/Jess_et_Samy.png'
import future from '../assets/img/jess-future.jpg'
import X from '../assets/img/polytechnique.jpg'
import Item from '../components/Item';
import corse from '../assets/img/corse.jpg'

const Slider = ({ currentSlide, setCurrentSlide }) => {

    const i18n = useTranslation();

    const slideData = [
        {
            id: "La Mairie", img: window.innerWidth > 950 ? require('../assets/img/bg_slider.jpg') : require('../assets/img/polytechnique.jpg'),
            phoneTitle:i18n.t("Slider.Mairie.title"),
            paragraphs:[i18n.t("Slider.Mairie.application"), i18n.t("Slider.Mairie.application 3")],
            image:X,
            title: <Slides
                type="1"
                image={X}
                title={[i18n.t("Slider.Mairie.title"), i18n.t("Slider.Mairie.title 2")]}
                app={[i18n.t("Slider.Mairie.application"), i18n.t("Slider.Mairie.application 3")]}
                doodle={olivier}/>
        },
        {
            id: "La Cérémonie", img: window.innerWidth > 950 ? require('../assets/img/bg_slider.jpg') : require('../assets/img/barthelemy.jpg'),
            phoneTitle:i18n.t("Slider.Ceremonie.title"),
            paragraphs:[i18n.t("Slider.Ceremonie.application"), i18n.t("Slider.Ceremonie.application 2"),
            i18n.t("Slider.Ceremonie.application 4")],
            image:future,
            title: <Slides
                type="1"
                image={future}
                title={[i18n.t("Slider.Ceremonie.title"), i18n.t("Slider.Ceremonie.title 2")]}
                app={[i18n.t("Slider.Ceremonie.application"), i18n.t("Slider.Ceremonie.application 2"),
                 i18n.t("Slider.Ceremonie.application 4")]}
                doodle={couple}
                />
        },

        {
            id: "Le Brunch", img: window.innerWidth > 950 ? require('../assets/img/bg_slider.jpg') : require('../assets/img/corse.jpg'),
            phoneTitle:i18n.t("Slider.Brunch.title"),
            paragraphs:[i18n.t("Slider.Brunch.application 2"), i18n.t("Slider.Brunch.application 3")],
            image:corse,
            title: <Slides
            type="1"
            image={corse}
            title={[i18n.t("Slider.Brunch.title"), i18n.t("Slider.Brunch.title 2")]}
            app={[i18n.t("Slider.Brunch.application 2"), i18n.t("Slider.Brunch.application 3")]}
            doodle={corail}
            />
        }
    ];

    return (
        window.innerWidth > 950 ? 
        <div>
            <Carousel className="sliderSize" infiniteLoop showStatus={false} showThumbs={false}
                selectedItem={currentSlide} // Utilisez selectedItem pour définir la diapositive actuelle
                onChange={(index) => setCurrentSlide(index)}
                renderArrowPrev={(clickHandler) => {
                    return (
                        <div className="sliderArrow leftArrow" onClick={clickHandler}>
                            <FaChevronLeft />
                        </div>
                    );
                }}
                renderArrowNext={(clickHandler) => {
                    return (
                        <div className="sliderArrow rightArrow" onClick={clickHandler}>
                            <FaChevronRight />
                        </div>
                    );
                }}
                renderIndicator={(onClickHandler, isSelected, index) => {
                    const style = isSelected
                    ? "sliderIndicatorsClicked"
                    : "sliderIndicators";
                    return (
                        <button className={style} onClick={onClickHandler} onKeyDown={onClickHandler}>
                            {slideData[index].id}
                        </button>
                    );
                }}
            >
                {slideData.map((slide) => (
                    <div key={slide.id}>
                        <img src={slide.img} alt="" />
                        <div>{slide.title}</div>
                    </div>
                )
                )}
            </Carousel>
        </div>
        :
        <div >
             {slideData.map((slide) => 
             (
                <Item
                background="bg3"
                type={""+slide.id}
                title={slide.phoneTitle}
                paragraphs={slide.paragraphs}
                content={slide.image}
                />
                )
                )}

        </div>
    );
};

export default Slider;