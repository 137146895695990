import { useTranslation } from "react-i18next";

const Slides = (props) => {

    const { type, image, title, app, size, feat, gain, doodle } = props

    const i18n = useTranslation();

    //<h1 className={"sliderBand" + type}>{title[0]} <br /> {title[1]}</h1>

    return (
        <div>
            <div className={"sliderBlock sliderBlock" + type}>
                <div className={"sliderGrid" + type} id="col" data-aos="fade-left">
                    <div className={"sliderRow sliderRow" + type} >
                        {app.map((paragraph, i) => (
                            <p key={i}>
                                {paragraph}
                            </p>
                        ))}
                    </div>
                    {doodle ? 
                    <div className="slideDoodle">     
                       <img alt="" src={doodle}>
                        </img>
                    </div>
                    : null}
                </div> 
                <div id="col" className="slideImageCol">
                    <div className="slideImage">
                        <img  src={image} alt="" data-aos="fade" data-aos-duration="3000"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slides;